<!-- 接龙参与列表 -->
<template>
  <Layout>
    <div class="back-view">
      <div class="top-view">
        <el-select
          @change="sortTypeChange()"
          v-model="sortType"
          placeholder="排序"
          clearable
          style="margin-left: 10px"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="list-back-view">
        <el-table border :data="tableData" >
          <el-table-column type="index" label="序号" width="50" align="center">
          </el-table-column>
          <el-table-column
            prop="nickname"
            label="用户姓名"
            width="180"
            align="center"
          >
          </el-table-column>

          <el-table-column label="是否允许他人查看" width="180" align="center">
            <template slot-scope="scope">
              <div style="display: flex; margin-left: 38px">
                <el-switch
                  v-model="scope.row.canLook"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  @change="switchChange(scope.row)"
                >
                </el-switch>
                <div style="margin-left: 5px">
                  {{ scope.row.canLook == true ? "允许" : "不允许" }}
                </div>
              </div>
            </template>
          </el-table-column>
          <!-- :width="item.type == 5?'180':item.type == 6?'180':item.type == 12?'180':'100'" -->
          <el-table-column
            v-for="(item, tindex) in titleArray"
            :key="tindex"
          
            :label="item.title"
            align="center"
            
          >
            <!-- scope.row.type  5 图片  6 视频   12 地址-->
            <template slot-scope="scope">
              <div v-if="scope.row.sign_info[tindex].type == 5">
                <el-image
                  style="width: 180px; height:180px"
                  :src="scope.row.sign_info[tindex].value"
                ></el-image>
              </div>
              <div v-if="scope.row.sign_info[tindex].type == 6">
                <video
                  :id="'myVideo' + scope.$index"
                  :src="scope.row.sign_info[tindex].value"
                  enable-danmu="true"
                  mode="widthFix"
                  :show-fullscreen-btn="false"
                  style="width: 180px; height:180px"
                  controls
                ></video>
              </div>
              <div v-if="scope.row.sign_info[tindex].type == 12">
                {{ scope.row.sign_info[tindex].value }}
              </div>
              <div
                v-if="
                  scope.row.sign_info[tindex].type != 5 &&
                  scope.row.sign_info[tindex].type != 6 &&
                  scope.row.sign_info[tindex].type != 12
                "
              >
                {{ scope.row.sign_info[tindex].value }}
              </div>
            </template>
          </el-table-column>

          <el-table-column
           fixed="right"
            label="操作"
            width="300"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                icon="el-icon-view"
                style="margin-right: 10px; color: #019688; font-size: 14px"
                type="text"
                size="small"
                @click="lookClick(scope.row)"
                >查看</el-button
              >
              <el-button
                icon="el-icon-edit"
                style="margin-right: 10px; color: #019688; font-size: 14px"
                type="text"
                size="small"
                @click="editClick(scope.row)"
                >编辑</el-button
              >
              <el-button
                icon="el-icon-delete"
                style="margin-right: 10px; color: #019688; font-size: 14px"
                type="text"
                size="small"
                @click="delClick(scope.row)"
                >删除</el-button
              >
              <el-button
                icon="el-icon-chat-line-round"
                style="margin-right: 10px; color: #019688; font-size: 14px"
                type="text"
                size="small"
                @click="talkClick(scope.row)"
                >点评</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div style="text-align: center; margin-top: 20px" v-show="tablePage.total">
          <el-pagination
            background
            layout="sizes,prev, pager, next,total"
            :page-sizes="[20, 50, 100, 200]"
            :total="tablePage.total"
            :current-page="tablePage.pageNum"
            :page-size="tablePage.pageSize"
            @size-change="handleSizeChange"
            @current-change="handlePageChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <!-- 抽屉弹窗 -->
    <el-drawer
      title="查看用户"
      :visible.sync="drawer"
      :direction="direction"
      :before-close="handleClose"
      :size="600"
    >
      <div style="margin: 10px">
        <el-form
          label-position="right"
          ref="form"
          :model="form"
          label-width="130px"
        >
          <!-- :rules="{
              required: true,
              message: '不能为空',
              trigger: 'blur',
            }" -->
          <el-form-item
            v-for="(infoItem, index) in detailPeople.signInfoArray"
            :key="infoItem.id + index"
            :label="infoItem.fieldName"
            :rules="{
              required: infoItem.isRequired == 1,
              message: '不能为空',
              trigger: 'blur',
            }"
          >
            <!-- infoItem.type  5 图片  6 视频   12 地址-->
            <div
              v-if="infoItem.type == 5"
              style="display: flex; flex-wrap: wrap"
            >
              <div
                v-for="(pic, picIndex) in infoItem.value"
                :key="picIndex"
                class="pic-back"
              >
                <el-image
                  style="width: 120px; height: 80px"
                  :src="imageUrl + pic"
                  :preview-src-list="[imageUrl + pic]"
                ></el-image>
                <div class="pic-del-back">
                  <el-button
                    v-show="canEdit"
                    @click="delPicClick(index, picIndex)"
                    type="text"
                    icon="el-icon-error"
                    style="color: darkgray; font-size: 20px"
                  ></el-button>
                </div>
              </div>
              <div class="pic-add" v-show="infoItem.value.length < 9">
                +
                <input
                  class="updateFileBtn"
                  type="file"
                  id="imageInput"
                  multiple
                  @change="(event) => handleImgUpload(event, index)"
                />
              </div>
            </div>
            <!-- 视频 -->
            <div v-if="infoItem.type == 6">
              <video
                v-show="infoItem.value"
                :id="'video'"
                :src="imageUrl + infoItem.value"
                enable-danmu="true"
                mode="widthFix"
                :show-fullscreen-btn="false"
                style="width: 100%; height: 190px"
                controls
              ></video>
              <div v-show="infoItem.value == ''">暂无</div>
              <div class="addSmallBtn b-marT-10" v-show="canEdit == true">
                <input
                  class="updateVideoBtn"
                  type="file"
                  id="imageInput5"
                  accept=".mp4"
                  @change="(event) => handleMpFourUpload(event, index)"
                />
                <i class="el-icon-circle-plus"></i>&nbsp;点击上传视频
              </div>
            </div>
            <!-- 音频 -->
            <div v-if="infoItem.type == 7">
              <div>{{ infoItem.value.name }}</div>
              <audio controls ref="audio" id="myAudio">
                <source :src="infoItem.value.url" />
              </audio>

              <div class="addSmallBtn b-marT-10" v-show="canEdit == true">
                <input
                  class="updateVideoBtn"
                  type="file"
                  id="imageInput4"
                  accept=".mp3"
                  @change="(event) => handleMpThreeUpload(event, index)"
                />
                <i class="el-icon-circle-plus"></i>&nbsp;点击上传音频
              </div>
            </div>
            <!-- 地图 -->
            <div v-if="infoItem.type == 12">
              <div>{{ infoItem.value.name }}</div>
              <div>{{ infoItem.value.address }}</div>
              <div v-show="infoItem.value == ''">暂无</div>
              <el-button
                v-show="infoItem.value && canEdit"
                @click="changeAddressClick(index)"
                type="text"
                class="del-button"
                >修改</el-button
              >
            </div>
            <!-- 多选 -->

            <div v-if="infoItem.type == 4">
              <el-checkbox-group v-model="infoItem.value">
                <el-checkbox
                  v-for="(dItem, dindex) in infoItem.contentArray"
                  :key="dindex"
                  :label="dItem.value"
                ></el-checkbox>
                <!-- <el-checkbox label="复选框 B"></el-checkbox>
                <el-checkbox label="复选框 C"></el-checkbox>
                <el-checkbox label="禁用" disabled></el-checkbox>
                <el-checkbox label="选中且禁用" disabled></el-checkbox> -->
              </el-checkbox-group>
            </div>
            <div
              v-if="
                infoItem.type != 4 &&
                infoItem.type != 5 &&
                infoItem.type != 6 &&
                infoItem.type != 7 &&
                infoItem.type != 12
              "
            >
              <div v-if="canEdit == false">{{ infoItem.value }}</div>
              <el-input v-else v-model="infoItem.value"></el-input>
            </div>
          </el-form-item>

          <el-divider></el-divider>
          <el-form-item label="评语">
            <el-input
              v-if="canTalk == true"
              type="textarea"
              v-model="detailPeople.addcomment"
            ></el-input>
            <div v-else>{{ detailPeople.addcomment }}</div>
          </el-form-item>
          <el-form-item>
            <el-button
              v-show="canTalk == true || canEdit == true"
              type="primary"
              @click="onSubmit()"
              >提交</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>

    <!-- 地图 -->
    <el-dialog
      title="提示"
      width="600px"
      :visible.sync="addressDialog"
      :destroy-on-close="true"
      center
    >
      <iframe
        id="mapPage2"
        width="100%"
        height="100%"
        style="width: 550px; height: 600px"
        frameborder="0"
        src="https://apis.map.qq.com/tools/locpicker?search=1&type=1&key=LG3BZ-FLACZ-YJPX7-ZKGLD-ZODTK-JGB34&referer=myapp"
      >
      </iframe>
      <span slot="footer">
        <el-button @click="addressDialog = false">取 消</el-button>
        <el-button type="primary" @click="submitMap">确 定</el-button>
      </span>
    </el-dialog>
  </Layout>
</template>
    <script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
// import router from "../../../router/index";
/**
 * Basic-table component
 */
import {
  getSignList,
  sleDetails,
  updateSignInfo,
  getProjectManageAdmin,
  updatecommentlanguage,
  updateSignInfoLook,
  signDel,
} from "@/utils/api/activity";
import { imageUrl } from "@/utils/config/commonUrl.js";
import serverConfig from "@/utils/config/index.js";
import axios from "axios";

export default {
  page: {
    title: "报名列表",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout },
  data() {
    return {
      addressDialog: false,

      addressIndex: null,
      address: "",
      poiname: "", //地址名称
      longitude: "", //纬度
      latitude: "", //经度

      imageUrl: imageUrl,
      activityId: "",
      drawer: false,
      direction: "rtl",
      form: {},

      options: [
        {
          label: "倒序",
          value: "1",
        },
        {
          label: "正序",
          value: "2",
        },
        {
          label: "姓名排序",
          value: "3",
        },
      ],
      sortType: "1",
      tableData: [],

      titleArray: [],

      tablePage: {
        pageNum: 1, //
        pageSize: 20,
        total: 0,
      },
      editItem: null, //查看，编辑项目

      detailPeople: {
        signInfoArray: [],
        addcomment: "",
      }, //用户详情

      isOtherPeople: "", // 1=创建者、2=管理员、3=普通用户

      canEdit: false, //判断是否可以编辑
      canTalk: false, //判断是否可以评论

      userID: localStorage.getItem("userID"),
      selectedFile: null,
    };
  },
  mounted() {
    this.subAddress();
  },
  created() {
    if (this.$route.query.activityId) {
      this.activityId = this.$route.query.activityId;
      this.getMessage();
      this.getProjectManageAdmin();
    }
    // 获取身份
  },
  methods: {
    // 上传音频
    handleMpThreeUpload(event, idx) {
      var that = this;
      this.selectedFile = event.target.files;
      console.log(this.selectedFile, "文件信息啊--音频");
      for (let i = 0; i < this.selectedFile.length; i++) {
        let name = this.selectedFile[i].name;
        let size = this.selectedFile[i].size;
        // 判断后缀名
        let lastArr = this.selectedFile[i].name.split(".");
        if (lastArr[1] != "mp3") {
          this.$message("文件格式不正确");
          return;
        } else {
          // 加载中
          const loading = this.$loading({
            lock: true,
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          if (this.selectedFile[i].size / 1048576 > 10) {
            this.$message("文件大小小于5M");
            break;
          } else {
            var parm = {
              file: this.selectedFile[i],
              type: "modularMP3",
            };

            axios
              .post(serverConfig.baseURL + "/file/uploadFile", parm, {
                headers: {
                  "Content-Type": "multipart/form-data",
                  "Access-Token": localStorage.getItem("token"),
                },
              })
              .then((response) => {
                console.log("文件上传成功", response);
                loading.close();
                if (response.data.status == 1) {
                  var _json = {
                    name: name,
                    size: size,
                    fileUrl: response.data.msg,
                    lastName: lastArr[1],
                  };
                  console.log(_json, "json三生三世");

                  that.detailPeople.signInfoArray[idx].value.name = _json.name;
                  that.detailPeople.signInfoArray[idx].value.size = _json.size;
                  that.detailPeople.signInfoArray[idx].value.url =
                    this.imageUrl + _json.fileUrl;
                  // that.modularList[this.modulCommonIndex].info.filesArr.push(
                  //   _json
                  // );
                }
              })
              .catch((error) => {
                loading.close();
                console.log("err:", error);
              });
          }
        }
      }
    },
    // 上传视频
    handleMpFourUpload(event, idx) {
      console.log("视频啊=======");
      var that = this;
      this.selectedFile = event.target.files[0];
      console.log(this.selectedFile, "文件信息啊7878787");
      let lastArr = this.selectedFile.name.split(".");
      console.log(lastArr, "格式不对？？？");
      if (lastArr[1] != "mp4") {
        this.$message("文件格式不正确");
        return;
      }
      if (this.selectedFile.size / 1048576 > 10) {
        this.$message("文件大小小于10M");
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var parm = {
        file: this.selectedFile,
        type: "modularMP4",
      };
      axios
        .post(serverConfig.baseURL + "/file/uploadFile", parm, {
          headers: {
            "Content-Type": "multipart/form-data",
            "Access-Token": localStorage.getItem("token"),
          },
        })
        .then((response) => {
          loading.close();
          console.log("上传成功", response);
          that.detailPeople.signInfoArray[idx].value = response.data.msg;
        })
        .catch((error) => {
          loading.close();
          console.log("图片上传失败", error);
        });
    },

    // 上传图片
    handleImgUpload(event, idx) {
      // this.modulCommonIndex = idx;
      this.selectedFile = event.target.files;
      console.log(event, "event");
      var pettern = /^image/;
      for (let i = 0; i < 9; i++) {
        if (!this.selectedFile[i]) {
          return;
        }
        if (!pettern.test(this.selectedFile[i].type)) {
          this.$message("文件格式不正确");
          return;
        }
        if (this.selectedFile[i].size / 1048576 > 5) {
          this.$message("图片大小小于5M");
        } else {
          var parm = {
            file: this.selectedFile[i],
            type: "modularImg",
          };

          axios
            .post(serverConfig.baseURL + "/file/uploadFile", parm, {
              headers: {
                "Content-Type": "multipart/form-data",
                "Access-Token": localStorage.getItem("token"),
              },
            })
            .then((response) => {
              console.log("图片上传成功", response);
              if (response.data.status == 1) {
                if (this.detailPeople.signInfoArray[idx].value.length >= 9) {
                  this.$message("最多添加9张图片");
                } else {
                  this.detailPeople.signInfoArray[idx].value.push(
                    response.data.msg
                  );
                }
              }
            })
            .catch((error) => {
              console.log("err:", error);
            });
        }
      }
    },

    subAddress() {
      window.addEventListener(
        "message",
        (event) => {
          // console.log("this.addressIdx", this.addressIdx)
          // 接收位置信息，用户选择确认位置点后选点组件会触发该事件，回传用户的位置信息
          const loc = event.data;
          // console.log(loc,'jjjj')
          // _this.addressData = []
          if (loc && loc.module == "locationPicker") {
            //防止其他应用也会向该页面post信息，需判断module是否为'locationPicker'
            this.address = loc.poiaddress;
            this.poiname = loc.poiname;
            this.latitude = loc.latlng.lat;
            this.longitude = loc.latlng.lng;
          }
        },
        false
      );
    },
    //地图选点确定
    submitMap() {
      if (
        this.address == null ||
        this.latitude == null ||
        this.longitude == null
      ) {
        this.$message.error("请先选择地址！");
        return;
      }
      console.log(this.address, "this.address");
      this.addressDialog = false;

      this.detailPeople.signInfoArray[this.addressIndex].value.address =
        this.address;
      this.detailPeople.signInfoArray[this.addressIndex].value.name =
        this.poiname;
      this.detailPeople.signInfoArray[this.addressIndex].value.latitude =
        this.latitude;
      this.detailPeople.signInfoArray[this.addressIndex].value.longitude =
        this.longitude;
    },

    //修改地址
    changeAddressClick(index) {
      this.addressDialog = true;

      this.addressIndex = index;
    },
    // 更换视频
    changeVideoClick(index) {
      // this.detailPeople.signInfoArray[index].value = "";
    },
    //删除一张图片
    delPicClick(index, picIndex) {
      this.detailPeople.signInfoArray[index].value.splice(picIndex, 1);
    },

    //开关是否允许查看
    switchChange(row) {
      console.log(row);
      // 是否允许查看接口 allow_viewing: 2 //是否允许他人查看 默认1 允许 2 不允许
      updateSignInfoLook({
        joinNum: 1,
        projectVos: [],
        activityId: this.activityId,
        allow_viewing: row.canLook == true ? "1" : "2",
        fmSignListId: row.fm_sign_list_id,
        id: row.id,
        isItOneself: this.isOtherPeople == 3 ? 1 : 2,

        signInfo: row.old_sign_info,
      }).then((res) => {
        if (res.status == 1) {
          this.$message({
            message: "修改成功",
            type: "success",
          });

          this.getMessage();
        } else {
          this.$message({
            message: res.msg,
            type: "false",
          });
        }
      });
    },
    getProjectManageAdmin() {
      getProjectManageAdmin({ activityId: this.activityId }).then((res) => {
        this.isOtherPeople = res.data;
      });
    },

    handleSizeChange(pageSize) {
      this.tablePage.pageNum = 1;
      this.tablePage.pageSize = pageSize;
      this.getMessage();
    },
    handlePageChange(currentPage) {
      this.tablePage.pageNum = currentPage;
      this.getMessage();
    },

    sortTypeChange() {
      this.getMessage();
    },
    getMessage() {
      // this.$refs.audio.pause();
      this.stopAudio();
      this.stopVideo();
      getSignList({
        pageSize: this.tablePage.pageSize,
        pageNum: this.tablePage.pageNum,
        signShowType: 1,
        activityId: this.activityId,
        sortType: this.sortType,
      }).then((res) => {
        if (res.status == 1) {
          this.tablePage.total = res.data.pageInfo.total;
          if (res.data.pageInfo.list.length > 0) {
            var titleArr = res.data.pageInfo.list[0].sign_info;
            for (const key in titleArr) {
              let strArr = titleArr[key].key.split("_");

              titleArr[key].title = strArr[0];
              titleArr[key].id = strArr[1];
            }
            this.titleArray = titleArr;

            console.log(this.titleArray, "titleArraytitleArray");

            for (const key in res.data.pageInfo.list) {
              let item = res.data.pageInfo.list[key];
              if (item.allow_viewing == 2) {
                item.canLook = false;
              } else {
                item.canLook = true;
              }

              for (const subkey in item.sign_info) {
                let subItem = item.sign_info[subkey];
                if (subItem.type == 5 && subItem.value.length > 0) {
                  subItem.value = subItem.value.split(",")[0];
                }
              }

              console.log(item, "列表数据");
            }
            // 判断是否允许查看
          }
          this.tableData = res.data.pageInfo.list;
        }
      });
    },

    //关闭弹窗
    handleClose() {
      this.drawer = false;

      // this.$refs.audio.pause();
      this.stopAudio();
      this.stopVideo();
    },

    stopAudio() {
      var audio = document.getElementById("myAudio");
      if (audio) {
        audio.pause();
      }
    },

    stopVideo() {
      const video = document.getElementById("video");
      if (video) {
        video.pause();
      }
    },

    //查看
    lookClick(row) {
      this.editItem = row;
      this.canEdit = false;
      this.canTalk = false;
      this.getDetail(row.id);
    },
    //编辑
    editClick(row) {
      this.canEdit = true;
      this.canTalk = false;
      this.editItem = row;
      this.getDetail(row.id);
    },

    talkClick(row) {
      this.canEdit = false;
      this.canTalk = true;
      this.editItem = row;
      this.getDetail(row.id);
    },
    //删除
    delClick(row) {
      var that = this;
      this.$confirm("确定删除该用户吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          signDel({
            activityId: this.activityId,
            id: row.id,
            userId: this.userID,
          }).then((res) => {
            if (res.status == 1) {
              this.$message({
                message: "修改成功",
                type: "success",
              });
              that.getMessage();
            } else {
              this.$message({
                message: res.msg,
                type: "false",
              });
            }
          });
        })
        .catch(() => {});
    },

    // 获取详情
    getDetail(id) {
      sleDetails({
        activityId: this.activityId,
        id: id,
      }).then((res) => {
        console.log(res, "详情数据");
        if (res.status == 1) {
          this.drawer = true;
          var detailMessage = res.data[0];
          let signInfoArray = JSON.parse(detailMessage.signInfo);

          for (const key in signInfoArray) {
            let item = signInfoArray[key];
            if (item.type == 4) {
              // 多选
              let contentArray = JSON.parse(item.content);
              item.contentArray = contentArray;
            }
          }

          detailMessage.signInfoArray = signInfoArray;
          this.detailPeople = detailMessage;
        } else {
          this.$message({
            message: res.msg,
            type: "false",
          });
        }
      });
    },

    // 修改提交
    onSubmit() {
      if (this.canTalk == true) {
        // 评论接口
        this.updatecommentlanguage();
      } else {
        // 编辑接口

        this.editSupport();
      }
    },

    // 评论
    updatecommentlanguage() {
      updatecommentlanguage({
        activityId: this.activityId,

        id: this.detailPeople.id,
        addcomment: this.detailPeople.addcomment,
      }).then((res) => {
        if (res.status == 1) {
          this.$message({
            message: "修改成功",
            type: "success",
          });

          this.drawer = false;
          this.getMessage();
        } else {
          this.$message({
            message: res.msg,
            type: "false",
          });
        }
      });
    },
    editSupport() {
      for (const key in this.detailPeople.signInfoArray) {
        let item = this.detailPeople.signInfoArray[key];

        if (item.value == "" && item.isRequired == 1) {
          this.$message.error(item.fieldName + "不能为空");
          return;
        }
      }

      let signInfo = JSON.stringify(this.detailPeople.signInfoArray);
      // 修改报名信息接口
      updateSignInfo({
        activityId: this.activityId,
        signInfo: signInfo,
        fmSignListId: this.detailPeople.fmSignListId,
        joinNum: 1,
        name: this.detailPeople.name,
        id: this.detailPeople.id,
        projectVos: [],
        isItOneself: this.isOtherPeople == 3 ? 1 : 2,
      }).then((res) => {
        if (res.status == 1) {
          this.$message({
            message: "修改成功",
            type: "success",
          });

          this.drawer = false;
          this.getMessage();
        } else {
          this.$message({
            message: res.msg,
            type: "false",
          });
        }
      });
    },
  },
};
</script>
    <style lang="scss" scoped>
.back-view {
  background-color: #ffffff;
  width: 100%;
  min-height: 500px;
}

.top-view {
  height: 81px;
  display: flex;
  padding-top: 31px;
  padding-left: 18px;
}
.list-back-view {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 30px;
  // width: 1080px;
}

.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
  font-size: 12px;
}
.el-icon-arrow-down {
  font-size: 10px;
}
.demonstration {
  display: block;
  color: #8492a6;
  font-size: 14px;
  margin-bottom: 20px;
}
.pic-back {
  margin: 5px;
  width: 120px;
  height: 80px;
}
.pic-del-back {
  width: 15px;
  height: 15px;
  position: relative;
  top: -108px;
  right: -99px;
}
.pic-add {
  margin: 5px;
  width: 120px;
  height: 80px;
  background-color: lightgrey;
  text-align: center;
  line-height: 70px;
  font-size: 40px;
}
.del-button {
  color: darkgray;
  font-size: 15px;
  text-align: center;
  width: 100%;
}
.updateFileBtn {
  width: 120px;
  height: 80px;
  position: relative;
  margin-top: -88px;
  top: -69px;
  left: 0;
  opacity: 0;
  cursor: pointer;
}
.updateVideoBtn {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
.addSmallBtn {
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f7f7;
  color: #1877ff;
  border-radius: 4px;
  position: relative;
}
// .el-dropdown-menu {
//   z-index: 3000 !important;
// }
</style>